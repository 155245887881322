import React from "react";
import { Button, useClipboard } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { CheckCircleIcon } from "@chakra-ui/icons";

function CopyButton(props) {
  const { hasCopied, onCopy } = useClipboard(props.content);

  return (
    <Button fontWeight="medium"
    fontSize="sm" size="sm" bg="white" variant="outline" leftIcon={hasCopied ? <CheckCircleIcon color="green" mb="2px" /> : null } ml={2} onClick={onCopy}>
      {hasCopied ? "Másolva!" : "Másolás"}
    </Button>
  );
}

CopyButton.propTypes = {
    content: PropTypes.string,
  };

export default CopyButton;