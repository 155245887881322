import React from "react";
import { Flex, Box, Link, Button, Popover, PopoverTrigger, PopoverContent, PopoverBody, Stack } from "@chakra-ui/react";
import { graphql, useStaticQuery, Link as GatsbyLink } from "gatsby";
import ToggleContent from "./atoms/toggleContent"
import PropTypes from "prop-types";

function Header2() {

  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery2 {
      site {
        siteMetadata {
          brand
        }
      }
    }
  `);

  return (
    <Box
      zIndex="2"
      as="header"
      position="fixed"
      top="0px"
      width="full"
      px={[4, 4, 12]}
      color="white"
      bg="white"
      borderBottom={("1px solid", "1px solid", "none")}
      borderColor="brand.gray.200"
      shadow={["md", "md", "none"]}
    >
      <Flex
        as="nav"
        alignItems="center"
        justifyContent="space-between"
        height={[16, 16, 24]}
        bg="white"
      >
        <Flex alignItems="center" flexShrink="0" color="brand.indigo.500">
          <Link as={GatsbyLink} to="/" style={{ textDecoration: "none" }}>
            <Box
              as="span"
              color="brand.indigo.500"
              _hover={{ color: "brand.indigo.600" }}
              fontSize="2xl"
              fontWeight="extrabold"
              letterSpacing="tight"
            >
              {site.siteMetadata.brand}
            </Box>
          </Link>
        </Flex>
        <Box
          flexGrow="1"
          ml={[4, 8, 16]}
          mr={[0, 0, 16]}
          borderBottom="1px solid"
          borderColor="black"
        />
        <Stack display={["none", "none", "flex"]} direction="row" spacing={6} mr={12}>
          <MenuLink linkTo="/ingatlanok" content="Ingatlanok" />
          <MenuLink linkTo="/enteriorok" content="Enteriőrök" />
          <MenuLink linkTo="/epuletek" content="Épületek" />
          <MenuLink linkTo="/varoskep" content="Városkép" />
          <MenuLink linkTo="/magamrol" content="Magamról" />
        </Stack>
        <Popover>
          <PopoverTrigger>
          <Button 
              target='_blank'
              px={10}
              display={["none", "none", "block"]}
              fontWeight="semibold"
              fontSize="md"
              color="white"
              bg="brand.indigo.500"
              _hover={{ bg: "brand.indigo.600", boxShadow: "md" }}
              _active={{ bg: "brand.indigo.700" }} 
              size="lg">
              Elérhetőségek
            </Button>
          </PopoverTrigger>
          <PopoverContent right="47px" minWidth="340px">
            <PopoverBody px={6} py={2} color="initial">
              <ToggleContent/>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Flex>
    </Box>
  );
}


export const MenuLink = (props) => (
  <Link
      as={GatsbyLink}
      to={props.linkTo}
      fontSize="md"
      fontWeight="medium"
      color="black"
      _hover={{ textDecor: "none" }}
      activeStyle={{ color: "#0045FF"}}
    >
      {props.content}
  </Link>
)

MenuLink.propTypes = {
  content: PropTypes.string,
  linkTo: PropTypes.string,
};


export default Header2;
