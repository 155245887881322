import {
  Box,
  Flex,
  // Container,
} from "@chakra-ui/react";
import React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion"

import Header from "./header-2";
import Footer from "./footer-2";

function Layout2({ children }) {
  return (
    <Flex flexDirection="column" minHeight="100vh" width="full">
      <Header />
        <Box as="main" pt={[20, 20, 24]} pb={[0, 0, 24]} >
          <motion.div initial={{ opacity: 0 }}  transition={{ duration: 0.5 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            {children}
          </motion.div>
        </Box>
      <Footer/>
    </Flex>
  );
}

  
  Layout2.propTypes = {
    children: PropTypes.node.isRequired,
  };
  
  export default Layout2;