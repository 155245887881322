import React from "react";
import {
  Button,
  Text,
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Icon,
  Spacer,
  LinkBox,
  LinkOverlay,
} from "@chakra-ui/react";
import CopyButton from "./copyButton"
import { ChevronRightIcon } from "@chakra-ui/icons";
// import { FaCalculator } from "react-icons/fa"
import { Link as GatsbyLink } from "gatsby";

function ToggleContact() {

    const phoneNumber = "+36 30 843 2007";
    const emailAddress = "info@tamasgabor.com";
  
    return (
      <>
        <Accordion allowToggle allowMultiple="false">

        {/* Phone number */}
        <AccordionItem borderTop="0px">
          
          <h2>
            <AccordionButton _hover={{color: ["none", "none", "brand.indigo.500"]}} py={6} px={0}>
              <Flex display="flex" flex="1" flexDirection="row" alignItems="center" textAlign="left">
                <Call boxSize={5} color="brand.indigo.500" mr={4}/>
                <Text fontSize={["lg", "lg", "md"]} fontWeight="normal">Telefonszám</Text>
              </Flex>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel bg="white" pt={0} pb={8} >
            <Text fontSize="2xl" fontWeight="bold" mb={4}>{phoneNumber}</Text>
            <Button 
              as="a"
              href={`tel:${emailAddress}`}
              target='_blank'
              fontWeight="medium"
              fontSize="sm"
              color="white"
              bg="brand.indigo.500"
              _hover={{ bg: "brand.indigo.600" }}
              _active={{ bg: "brand.indigo.700" }} 
              size="sm">
              Hívás
            </Button>
            <CopyButton content={phoneNumber}/>
          </AccordionPanel>
        </AccordionItem>
        
        {/* Email Address */}
        <AccordionItem>
          <h2>
            <AccordionButton _hover={{color: ["none", "none", "brand.indigo.500"]}} py={6} px={0}>
              <Flex display="flex" flex="1" flexDirection="row" alignItems="center" textAlign="left">
                <Mail boxSize={5} color="brand.indigo.500" mr={4}/>
                <Text fontSize={["lg", "lg", "md"]} fontWeight="normal">E-mail cím</Text>
              </Flex>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel bg="white" pt={0} pb={8}>
            <Text fontSize="2xl" fontWeight="bold" mb={4}>{emailAddress}</Text>
            <Button 
              as="a"
              href={`mailto:${emailAddress}`}
              target='_blank'
              fontWeight="medium"
              fontSize="sm"
              color="white"
              bg="brand.indigo.500"
              _hover={{ bg: "brand.indigo.600" }}
              _active={{ bg: "brand.indigo.700" }}
              size="sm">
              Levélírás
            </Button>
            <CopyButton content={emailAddress}/>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <LinkBox py={6} display="flex" flex="1" flexDirection="row" alignItems="center" textAlign="left" _hover={{color: ["none", "none", "brand.indigo.500"]}}>
        <Form boxSize={5} color="brand.indigo.500" mr={4}/>
        <LinkOverlay as={GatsbyLink} to="/kapcsolat">
          <Text fontSize={["lg", "lg", "md"]} fontWeight="normal">Árajánlatkérő</Text>
        </LinkOverlay>
        <Spacer/>
        <ChevronRightIcon boxSize={5}/>
      </LinkBox>
      </>
    );
  }


  // Icons components
const Call = (props) => (
  <Icon viewBox="0 0 18 18" {...props}>
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.8"
        d="M12.5556 1H17m0 0v4.4444M17 1l-5.3333 5.3333M2.7777 1A1.7778 1.7778 0 001 2.7778v.8889C1 11.0302 6.9698 17 14.3333 17h.8889A1.7778 1.7778 0 0017 15.2222v-2.9155a.889.889 0 00-.608-.8427l-3.9938-1.3316a.8887.8887 0 00-1.0755.4463l-1.0045 2.0062a9.8151 9.8151 0 01-4.903-4.904l2.0061-1.0027a.8888.8888 0 00.4463-1.0755L6.536 1.607A.889.889 0 005.6924 1H2.7778z"
        />
    </Icon>
  )

  const Mail = (props) => (
    <Icon viewBox="0 0 19 15" {...props}>
        <path
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.8"
          d="M1.5 3.8337l7.0133 4.6755a1.7778 1.7778 0 001.9734 0L17.5 3.8337M3.2778 13.6114h12.4444A1.7778 1.7778 0 0017.5 11.8337v-8.889a1.7778 1.7778 0 00-1.7778-1.7777H3.2778A1.7778 1.7778 0 001.5 2.9448v8.8889a1.7776 1.7776 0 001.7778 1.7777z"
          />
      </Icon>
    )

    const Form = (props) => (
      <Icon viewBox="0 0 16 18" {...props}>
          <path
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.8"
            d="M3.8571 5.5714h3.4286M3.8571 9h8m-8 3.4286h6.8572M1.5714 1h8.9062a.5716.5716 0 01.4041.1674l3.6652 3.6652a.5713.5713 0 01.1674.404v11.192a.5714.5714 0 01-.5714.5714H1.5714A.5714.5714 0 011 16.4286V1.5714A.5714.5714 0 011.5714 1z"
            />
        </Icon>
      )

  export default ToggleContact;