import React from "react";
import {
  Text,
  Button,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Stack,
  HStack,
  Box,
  Link,
  Divider,
} from "@chakra-ui/react";
import { Link as GatsbyLink } from 'gatsby'
import { HamburgerIcon } from "@chakra-ui/icons";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa'
import ToggleContent from "./toggleContent"
import PropTypes from "prop-types";

function MenuDrawer () {

    const { isOpen, onOpen, onClose } = useDisclosure();
    const btnRef = React.useRef();

    return (
      <Box display={["block", "block", "none"]}>
          <Button
            size="lg"
            fontWeight="medium"
            borderRadius="full"
            leftIcon={<HamburgerIcon />}
            position="fixed"
            bottom="24px"
            right="16px"
            ref={btnRef}
            color="white"
            boxShadow="md"
            borderColor="brand.indigo.600"
            borderWidth="1px"
            bg="brand.indigo.500"
            _hover={{ bg:"brand.indigo.600" }}
            _active={{ bg:"brand.indigo.700" }}
            onClick={onOpen}
          >
            Menü
          </Button>
          <Drawer
            size="full"
            isOpen={isOpen}
            placement="right"
            onClose={onClose}
            finalFocusRef={btnRef}
          >
            <DrawerOverlay>
              <DrawerContent>
                <DrawerCloseButton size="lg" width={12} height={12} top="18px" right="10px" />
                <DrawerBody pt={24} px={8}>
                  <Stack direction="column" spacing={4}>
                    <MenuLink linkTo="/ingatlanok" content="Ingatlanok" />
                    <MenuLink linkTo="/enteriorok" content="Enteriőrök" />
                    <MenuLink linkTo="/epuletek" content="Épületek" />
                    <MenuLink linkTo="/varoskep" content="Városkép" />
                    <MenuLink linkTo="/magamrol" content="Magamról" />
                  </Stack>
                  <Divider my={14} borderColor="gray.500"/>
                  <Stack direction="column" spacing={0}>
                    <Text fontSize="xl" fontWeight="bold" pt={0} pb={2}>Elérhetőségek</Text>
                    <ToggleContent/>
                  </Stack>
                  <Divider mt={10} mb={14}  borderColor="gray.500"/>
                  <Text pb={8} fontSize="xl" fontWeight="bold">Követés</Text>
                  <HStack flexWrap="wrap" spacing={3} mb={3}>
                    <Button as="a" target='_blank' href="https://twitter.com/tamas_gr" size="sm" colorScheme="twitter" leftIcon={<FaTwitter />}>
                      Twitter
                    </Button>
                    <Button as="a" target='_blank' href="https://www.facebook.com/gabortamasphoto" size="sm" colorScheme="facebook" leftIcon={<FaFacebook />}>
                      Facebook
                    </Button>
                  </HStack>
                  <HStack flexWrap="wrap" spacing={3} mb={16}>
                    <Button as="a" target='_blank' href="https://www.instagram.com/tamas.gr/" size="sm" color="white" bg="orange.200" bgGradient="linear(to-l,orange.200,pink.500,purple.500)" _hover={{bg:"orange.300", bgGradient:"linear(to-l,orange.300,pink.600,purple.600)"}} leftIcon={<FaInstagram />}>
                      Instagram
                    </Button>
                    <Button as="a" target='_blank' href="https://www.linkedin.com/in/tamas-gr/" size="sm" colorScheme="linkedin" leftIcon={<FaLinkedin />}>
                      LinkedIn
                    </Button>
                  </HStack>
                </DrawerBody>
              </DrawerContent>
            </DrawerOverlay>
          </Drawer>
      </Box>
    );
  }

  export const MenuLink = (props) => (
    <Link
        as={GatsbyLink}
        to={props.linkTo}
        fontSize="4xl"
        fontWeight="black"
        color="black"
        _hover={{ textDecor: "none" }}
        activeStyle={{ color: "#0045FF"}}
      >
        {props.content}
    </Link>
  )

  MenuLink.propTypes = {
    content: PropTypes.string,
    linkTo: PropTypes.string,
  };
  

  export default MenuDrawer;